import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@repo/ui/components/DropdownMenu.js'
import { LogoIcon } from '@repo/ui/components/Logo.js'
import { Separator } from '@repo/ui/components/Separator.js'
import { SidebarTrigger } from '@repo/ui/components/Sidebar.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@repo/ui/components/Tooltip.js'
import { Link } from 'react-router'
import { UserAvatar } from '~/components/UserAvatar'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { useFetcherForm } from '~/hooks/useFetcherForm'
import { UniversalSearch } from '~/routes/api.search/UniversalSearch'
import { GlobalSpinner } from '../GlobalSpinner'
import { OrgPicker } from './OrgPicker'

export const TOPBAR_HEIGHT = '3rem'

export const Topbar = () => {
  const user = useAuthenticatedUser()

  const { submit: logout, isSubmitting } = useFetcherForm({
    intent: 'logout',
    config: { action: '/logout' },
  })

  return (
    <div
      className="fixed z-20 flex w-full shrink-0 items-center justify-between overflow-x-auto border-b border-b-border bg-sidebar-background px-5"
      style={{ height: TOPBAR_HEIGHT }}
    >
      <div className="flex items-stretch justify-start gap-4">
        <Tooltip>
          <TooltipTrigger asChild>
            <SidebarTrigger className="place-self-center opacity-70 hover:bg-transparent" />
          </TooltipTrigger>

          <TooltipContent align="start" className="z-50">
            <div className="flex items-center justify-between gap-4">
              <span>Toggle sidebar</span>
              <span className="font-mono text-muted-foreground text-xs tracking-widest">
                ⌘B
              </span>
            </div>
          </TooltipContent>
        </Tooltip>

        <div className="flex items-center justify-center">
          <Link to="/">
            <LogoIcon className="size-7" />
          </Link>
        </div>

        <Separator orientation="vertical" className="w-0.5 rotate-12" />
        <OrgPicker />

        <GlobalSpinner />
      </div>

      <div className="flex items-stretch gap-4">
        <UniversalSearch />

        <DropdownMenu>
          <DropdownMenuTrigger>
            <UserAvatar src={user.avatarUrl} className="size-7" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              disabled={isSubmitting}
              onSelect={(e) => {
                e.preventDefault()
                logout()
              }}
            >
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
