import { Spinner } from '@repo/ui/components/Spinner.js'
import { useNavigation } from 'react-router'

export const GlobalSpinner = () => {
  const navigation = useNavigation()
  const isNavigating = Boolean(navigation.location)
  if (!isNavigating) return null
  return (
    <div className="flex items-center justify-center">
      <Spinner className="opacity-40" />
    </div>
  )
}
