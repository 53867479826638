import { Button } from '@repo/ui/components/Button.js'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@repo/ui/components/Dialog.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
} from '@repo/ui/components/Select.js'
import { useState } from 'react'
import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { useFetcherForm } from '~/hooks/useFetcherForm'
import { useMemberships } from '~/routes/_app/useMemberships'
import { CreateOrgForm } from '~/routes/api.organizations/CreateOrgForm'
import { SwitchOrganizationSchema } from '~/routes/api.organizations/route.config'

export const OrgPicker = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const org = useAuthenticatedOrg()
  const memberships = useMemberships()

  const { submit, isSubmitting } = useFetcherForm({
    intent: SwitchOrganizationSchema.shape.intent.value,
    config: {
      action: '/api/organizations',
    },
    onSuccess: () => setMenuOpen(false),
  })

  return (
    <>
      <Select
        value={org.id}
        onValueChange={(orgId) => submit({ orgId })}
        disabled={isSubmitting}
        open={menuOpen}
        onOpenChange={setMenuOpen}
      >
        <SelectTrigger asChild>
          <Button variant="ghost" size="sm" className="text-muted-foreground">
            <span className="font-medium ">{org.name}</span>
            <Icon name="chevrons-up-down" className="size-3.5" />
          </Button>
        </SelectTrigger>
        <SelectContent align="start" className="min-w-60">
          {memberships.map((membership) => (
            <SelectItem key={membership.orgId} value={membership.orgId}>
              <div className="font-medium">{membership.name}</div>
            </SelectItem>
          ))}

          <SelectSeparator />
          <button
            type="button"
            onClick={() => {
              setDialogOpen(true)
              setMenuOpen(false)
            }}
            className="flex items-center gap-2 px-2 py-1.5 text-sm"
          >
            <Icon name="plus" size="sm" />
            New organization
          </button>
        </SelectContent>
      </Select>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-sm">
          <DialogHeader>
            <DialogTitle>Create an organization</DialogTitle>
            <DialogDescription className="sr-only">
              Create a new organization
            </DialogDescription>
          </DialogHeader>
          <CreateOrgForm onSuccess={() => setDialogOpen(false)} />
        </DialogContent>
      </Dialog>
    </>
  )
}
