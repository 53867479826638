import { showNewMessage } from '@intercom/messenger-js-sdk'
import { Button } from '@repo/ui/components/Button.js'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  CommandShortcut,
} from '@repo/ui/components/Command.js'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@repo/ui/components/Dialog.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { Spinner } from '@repo/ui/components/Spinner.js'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { UserAvatar } from '~/components/UserAvatar'
import { VendorIcon } from '~/components/VendorIcon'
import { useFetcherForm } from '~/hooks/useFetcherForm'
import { AppRequestDialog } from '../api.requests/AppRequestDialog'
import { type SearchResult, UniversalSearchSchema } from './route.config'

export const UniversalSearch = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [requestDialogOpen, setRequestDialogOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<SearchResult[]>([])

  const navigate = useNavigate()

  const form = useFetcherForm<SearchResult[]>({
    intent: UniversalSearchSchema.shape.intent.value,
    config: { action: '/api/search' },
    onSuccess: (data) => setResults(data),
  })

  const handleSearch = (query: string) => {
    if (!query) return setResults([])
    if (query.length < 3) return
    form.submit({ query })
  }

  const resetSearch = useCallback(() => {
    setQuery('')
    setResults([])
  }, [])

  const goTo = (url: string) => {
    setDialogOpen(false)
    navigate(url)
  }

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setDialogOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  useEffect(() => {
    if (!dialogOpen) setTimeout(resetSearch, 200) // Avoid flickering
  }, [dialogOpen, resetSearch])

  const employees = results.filter((r) => r.type === 'employee')
  const vendors = results.filter((r) => r.type === 'vendor')
  const products = results.filter((r) => r.type === 'product')

  const listRef = useRef(null)

  return (
    <>
      <Button
        variant="outline"
        onClick={() => setDialogOpen(true)}
        className="h-8 min-w-48 justify-between border-border bg-background text-muted-foreground"
        size="xs"
      >
        <div className="flex items-center gap-2">
          <Icon name="search" className="size-3" />
          <span>Search anything...</span>
        </div>

        <CommandShortcut className="rounded bg-muted px-1 py-0.5 font-mono">
          ⌘K
        </CommandShortcut>
      </Button>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogHeader className="sr-only">
          <DialogTitle>Universal search</DialogTitle>
        </DialogHeader>
        <DialogContent className='absolute top-1/3 border-0 p-0 [&>[data-slot="close"]]:hidden'>
          <Command loop shouldFilter={false}>
            <div className="relative bg-secondary">
              <CommandInput
                placeholder="Search apps or users..."
                className="h-full rounded-md"
                value={query}
                onValueChange={(query) => {
                  setQuery(query)
                  handleSearch(query)
                }}
              />
              {form.isSubmitting ? (
                <CommandLoading className="absolute top-0 right-4 flex h-full items-center justify-center text-muted-foreground">
                  <Spinner size="md" />
                </CommandLoading>
              ) : null}
            </div>

            <CommandList
              ref={listRef}
              className="fixed top-14 w-full rounded-md bg-secondary p-1.5"
            >
              {results.length ? (
                <>
                  {employees.length ? (
                    <CommandGroup heading="Users">
                      {employees.map((employee) => (
                        <CommandItem
                          key={employee.id}
                          value={employee.id}
                          onSelect={() => goTo(`/team/${employee.id}`)}
                          className="flex items-center gap-2"
                        >
                          <UserAvatar src={employee.image} className="size-5" />
                          <span>{employee.name}</span>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  ) : null}

                  {vendors.length ? (
                    <CommandGroup heading="Vendors">
                      {vendors.map((vendor) => (
                        <CommandItem
                          key={vendor.id}
                          value={vendor.id}
                          onSelect={() => goTo(`/apps/${vendor.id}`)}
                          className="flex items-center gap-2"
                        >
                          {vendor.image ? (
                            <VendorIcon src={vendor.image} className="size-5" />
                          ) : null}
                          <span>{vendor.name}</span>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  ) : null}
                  {products.length ? (
                    <CommandGroup heading="Products">
                      {products.map((product) => (
                        <CommandItem
                          key={product.id}
                          value={product.id}
                          onSelect={() =>
                            goTo(
                              `/apps/${product.vendorId}?product=${product.id}`
                            )
                          }
                          className="flex items-center gap-2"
                        >
                          {product.image ? (
                            <VendorIcon
                              src={product.image}
                              className="size-5"
                            />
                          ) : null}

                          <span>{product.name}</span>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  ) : null}
                </>
              ) : query && !form.isSubmitting ? (
                <CommandEmpty className="w-full p-3 font-medium">
                  <span className="text-muted-foreground">
                    No results found.
                  </span>
                </CommandEmpty>
              ) : (
                <>
                  <CommandGroup heading="Pages">
                    <CommandItem onSelect={() => goTo('/')}>
                      <Icon name="arrow-click" />
                      Sessions
                    </CommandItem>
                    <CommandItem onSelect={() => goTo('/apps')}>
                      <Icon name="box-open" />
                      Apps
                    </CommandItem>
                    <CommandItem onSelect={() => goTo('/team')}>
                      <Icon name="users" />
                      Users
                    </CommandItem>
                    <CommandItem onSelect={() => goTo('/settings')}>
                      <Icon name="gear" />
                      Settings
                    </CommandItem>
                  </CommandGroup>
                  <CommandGroup heading="Actions">
                    <CommandItem
                      onSelect={() => {
                        setDialogOpen(false)
                        showNewMessage('')
                      }}
                    >
                      <Icon name="message" />
                      Send us a message
                    </CommandItem>
                    <CommandItem
                      onSelect={() => {
                        setDialogOpen(false)
                        setRequestDialogOpen(true)
                      }}
                    >
                      <Icon name="arrow-click" />
                      Request an app
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </DialogContent>
      </Dialog>

      <AppRequestDialog
        open={requestDialogOpen}
        onOpenChange={setRequestDialogOpen}
      />
    </>
  )
}
